import React from "react";
import {
  Box,
  Container,
  Row,
  Column,
  FooterLink,
  Heading,
} from "./FooterStyles";
import footer from "../styles/components/footer.css";
import { FaFacebookSquare, FaWhatsappSquare, FaTwitterSquare, FaMapMarkerAlt, FaCalendarAlt, FaPhoneAlt } from "react-icons/fa";
import { MdMail, MdAccessTimeFilled } from "react-icons/md";


const Footer = () => {
  return (
    <Box>
      <div className="container text container-footer">
        <div className="row expand">
          <div className="col-10 col-md-6 margin-bot">
            <h1 className="text-right">Contacto</h1>
            <div>
              <FaPhoneAlt className="icons-cont" />
              <span className="info-footer">8714574448</span>
            </div>
            <div>
              <FaPhoneAlt className="icons-cont" />
              <span className="info-footer">8712927464</span>
            </div>
            <div>
              <FaMapMarkerAlt className="icons-cont" />
              <span className="info-footer">
                Blvd. Revolución #6795 Oriente, Torreón Coahuila.
              </span>
            </div>
            <div>
              <MdMail className="icons-cont" />
              <span className="info-footer">
                leonardo.hernandez@hedesarentasmaquinaria.com
              </span>
            </div>
            <div>
              <MdMail className="icons-cont" />
              <span className="info-footer">
                claudia.torres@hedesarentasmaquinaria.com
              </span>
            </div>
          </div>
          <div className="col-10 col-md-3 margin-bot">
            <h1>Horarios</h1>
            <div>
              <FaCalendarAlt className="icons-cont" />
              <span className="info-footer">Lunes a Viernes</span>
            </div>
            <div>
              <MdAccessTimeFilled className="icons-cont" />
              <span className="info-footer"> 8:00 a.m. a 5:30 p.m.</span>
            </div>
            <div>
              <FaCalendarAlt className="icons-cont" />
              <span className="info-footer">Sábados</span>
            </div>
            <div>
              <MdAccessTimeFilled className="icons-cont" />
              <span className="info-footer">8:00 a.m. a 2:00 p.m.</span>
            </div>
          </div>
          <div className="col-10 col-md-3">
            <h1>Redes Sociales</h1>
            <div className="">
              <div className="d-flex">
                <a
                  href="https://www.facebook.com/hedesatrc"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaFacebookSquare className="icons-footer" />
                </a>
                <a
                  href="https://wa.me/+5218712927464"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaWhatsappSquare className="icons-footer" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default Footer