import React from 'react';
import data from '../../core/data/altura.json';
import { Card, CardGroup, Image } from 'react-bootstrap';
import bannercDrill from '../../img/drills/cordedDrills/banner_Drill.jpg'
import { Container } from './index';

export default function Altura() {
    return (
      <Container data={data}></Container>
      // <div className='Drills'>
      //   <div class="container">
      //     <div class="row">
      //       <div class="col-12 p-4">
      //         <Image src={bannercDrill} fluid />
      //       </div>
      //     </div>
      //     <div className="row">
      //         <p>{data.descCategoria}</p>
      //     </div>
      //     <div className="row">
      //       <CardGroup>
      //         {data && data.data.map((item, i) => {
      //             //let imf = require("./static/media/"+item.imgURL + ".png");
      //          return (
             
      //           <div key={i} className="col-4 p-4"> 
      //             <Card>
      //               <Card.Img variant="top" src={item.imgURL}/>
      //               <Card.Body>
      //                 <Card.Title>{item.nombre}</Card.Title>
      //                 <Card.Text>
      //                  {item.desc}
      //                 </Card.Text>
      //               </Card.Body>
      //               <Card.Footer>
      //                 <small className="text-muted">$1,250.00 + IVA</small>
      //               </Card.Footer>
      //             </Card>
      //           </div>
  
      //         )})
      //         }
      //       </CardGroup>
      //     </div>
      //   </div>
      // </div>
    );
  }