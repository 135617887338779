import React, { Fragment, useState } from 'react';
import data from '../../core/data/demolicion.json';
//import { Card, CardGroup, Image, Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { Card, CardGroup, Image, Modal, Button, Row, Col } from 'react-bootstrap';
import bannercDrill from '../../img/drills/cordedDrills/banner_Drill.jpg';
import catalogContainer from '../../styles/components/catalogContainer.css'
import { FaFacebookSquare, FaWhatsappSquare, FaTwitterSquare, FaMapMarkerAlt, FaCalendarAlt, FaPhoneAlt } from "react-icons/fa";
import { MdMail, MdAccessTimeFilled } from "react-icons/md";
import { Link } from 'react-router-dom'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from "yup";
import emailjs from 'emailjs-com';

export default function Container(props) {
  const { data } = props;
  const [showModal, setShowModal] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [itemModal, setItemModal] = useState({});
  const handleModal = (item) => {
    setShowModal(true);
    setItemModal(item);
  }

  const [validated, setValidated] = useState(false);

  {/*const handleSubmit = (event) => {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
      }

      setValidated(true);
  };*/}
  const handleSubmit = (values, actions) => {
    emailjs.init("user_qRkVBMFact3UxWi8xCutF")
    emailjs.send("service_hedesa", "template_x9n3j1s", {
      first_name: values.name,
      phone: values.phone,
      email:values.email,
      comments:`Me gustaría que me envien informaciòn sobre la disponibilidad de ${itemModal.nombre}`
    });
    actions.resetForm();
    setShowModalSuccess(true);
    setShowModal(false);
  };
  const SignupSchema = Yup.object().shape({
    name: Yup.string()
      .required('El campo nombre es requerido'),
    email: Yup.string()
      .required("Ingresa un correo electronico")
      .email('Ingresa un correo electronico valido'),
  });
const handleSendWhatsapp = () => {

}
  return (
    <Fragment>
      <div className='container-fluid'>
        <div className='row bg-img'>
          <div className='col-12'>
            <h1 className="title"> {data.title}</h1>
          </div>
        </div>
      </div>
      <div className='container'>

        <div className='row'>
          <div className='col'>
            <p className='font desc'>{data.descCategoria}</p>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <CardGroup>
              {data && data.data.map((item, i) => {
                return (
                  <div key={i} className="col-12 col-lg-4 p-4">
                    <Card onClick={() => handleModal(item)}>
                      <Card.Img className='img-container' variant="top" src={item.imgURL} />
                      <Card.Body>
                        <Card.Title>{item.nombre}</Card.Title>
                        <Card.Text>
                          {/* {item.desc} */}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                )
              })
              }
            </CardGroup>
          </div>
        </div>
      </div>
      

      <Modal
        centered
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title >
            {itemModal.nombre}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='container'>
            <div className='row'>
              <div className='col'>
                <div className='text-center' >
                  <Image className='img-modal' src={itemModal.imgURL} />
                </div>
              </div>
              <div className='col align-self-center'>
                <Formik
                  initialValues={{
                    name: "",
                    phone: "",
                    email: "",
                  }}
                  onSubmit={(values, actions) => handleSubmit(values, actions)}
                  validationSchema={SignupSchema}
                >

                  <Form>
                    <div className="mb-3 row " >
                      <div className="col-12 my-2">
                        <label className='text-pe' htmlFor="inputAddress">Nombre</label>
                        <Field id="name" name="name" type="text" className="form-control" />
                        <ErrorMessage name="name">{msg => <label className='text-error' htmlFor="inputAddress">{msg}</label>}</ErrorMessage>
                      </div>
                      <div className="col-12 my-2">
                        <label className='text-pe' htmlFor="phone">Télefono</label>
                        <Field maxLength={15} id="phone" name="phone" type="text" className="form-control" />
                      </div>
                      <div className="col-12 my-2">
                        <label className='text-pe' htmlFor="inputEmail4">Correo Electronico</label>
                        <Field id="email" name="email" type="email" className="form-control" />
                        <ErrorMessage name="email">{msg => <label className='text-error' >{msg}</label>}</ErrorMessage>
                      </div>
                    </div>
                    <Button className='btn btn-primary btn-lg text center btn-footer' type="submit">
                      <MdMail className="icons" />
                      Preguntar Disponibildad
                    </Button>
             
                  </Form>
                </Formik>
              </div>
            </div>
          </div>


        </Modal.Body>

      </Modal>

      <Modal
                size="sm"
                centered
                show={showModalSuccess}
                onHide={() => setShowModalSuccess(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="title-sm">
                        Mensaje enviado
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center' >
                        <p>Tu mensaje ha sido enviado éxitosamente, pronto nos contactaremos contigo</p>
                    </div>
                </Modal.Body>
            </Modal>

    </Fragment>

  );
}