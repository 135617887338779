import React from "react";
import home from '../styles/pages/home.css'

import MapContainer from "../components/MapContainer.js";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import { BsPersonCheckFill } from "react-icons/bs";
import { GiPayMoney } from "react-icons/gi"
import { MdDateRange } from "react-icons/md"

const Home = () => {
    return (
        <div className=" home-container container-fluid">
            <div className="row justify-content-center" >
                <div className="col g-0">
                    {/* <h1>homr</h1> */}
                    <img alt="HEDESA" className="logo" src="/images/Home/BannerIII.png" />
                </div>
            </div>

            <div className="marginTop justify-content-center text-color-b">
                <div className="row">

                    <div className="col-md-4 ml-100">
                        <h1 className='h1-font'>Manejamos las mejores marcas.</h1>
                        <span>Todas las marcas que encontrarás con nosotros son las de mejor calidad
                            y rendimiento, nuestros productos siempre se encuentran en condiciones óptimas
                            para el trabajo duro en todo momento.
                        </span>
                    </div>

                    <div className="col text-start ml-100">
                        <img alt="DeWalt" className="brand" src="/images/Home/brands/dewalt.png" />
                        <img alt="Hilti" className="brand" src="/images/Home/brands/hilti.png" />
                        <img alt="Honda" className="brand" src="/images/Home/brands/honda.png" />
                        <img alt="Joper" className="brand" src="/images/Home/brands/joper.png" />
                        <img alt="Skill" className="brand" src="/images/Home/brands/skill.png" />

                    </div>
                </div>
            </div>

            {/* <div className="row justify-content-center">
                <div className="minibanner"></div>
                <div className="justify-content-center text-color-w">
                    <div className="row section">
                        <div className="col-md-9">
                        </div>
                        <div className="col-md-3">
                            <h1>¿Por qué nosotros?</h1><p /><p /><p /><p />
                        </div>
                    </div>
                    <div className="row section">
                        <div className="col-md-3">
                            <span>Nuestros trámites son sencillos, y nos adaptamos a tu presupuesto.
                            </span>
                        </div>
                        <div className="col-md-3">
                            <span>Tenemos personal capacitado para brindarte la atención que te mereces.
                            </span>
                        </div>
                        <div className="col-md-3">
                            <span>Estamos establecidos desde 2015 en la laguna. Nuestros clientes nos han respaldado a lo largo de estos años.
                            </span>
                        </div>
                        <div className="col-md-3">
                            <span>Nuestro objetivo es brindarte el mejor servicio, nos encanta ayudarte a cumplir tus proyectos, brindandote las mejores herramientas.
                            </span>
                        </div>
                    </div>
                    <div className="row section">
                        <div className="col-md-3 text-center">
                            <GiPayMoney className="iconsh" />
                        </div>
                        <div className="col-md-3 text-center">
                            <BsPersonCheckFill className="iconsh" />
                        </div>
                        <div className="col-md-3 text-center">
                            <MdDateRange className="iconsh" />
                        </div>
                        <div className="col-md-3 text-center">
                            <AiOutlineFundProjectionScreen className="iconsh" />
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="row justify-content-center">
                <div className="minibanner"></div>
                <div className="justify-content-center text-color-w px-5">
                    <div className='row section txt-align-title'><h1 className='h1-font'>¿POR QUÉ NOSOTROS?</h1></div>
                    <div className="row section pt-5">
                       
                        <div className="col-lg-3 col-12">
                            <div className='row px-4'>
                                <GiPayMoney className="iconsh" />
                                <span>Nuestros trámites son sencillos, y nos adaptamos a tu presupuesto.</span>
                            </div>
                        </div>

                        <div className="col-lg-3 col-12">
                            <div className='row px-4'>
                            <BsPersonCheckFill className="iconsh" />
                                <span>Tenemos personal capacitado para brindarte la atención que te mereces.</span>
                            </div>
                        </div>

                        <div className="col-lg-3 col-12">
                            <div className='row px-4'>
                            <MdDateRange className="iconsh" />
                                <span>Nuestros clientes nos siguen respaldando desde nuestros inicios.</span>
                            </div>
                        </div>

                        <div className="col-lg-3 col-12 px-4">
                            <div className='row'>
                                <AiOutlineFundProjectionScreen className="iconsh" />
                                <span>Nuestro objetivo es brindarte el mejor servicio, nos encanta ayudarte a cumplir tus proyectos, brindandote las mejores herramientas.</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="row justify-content-center text-color-w section pt-4">
                <h2 className="mt-4 subtitle text-center h1-font">VISITA NUESTRA SUCURSAL</h2>
                <MapContainer />
            </div>
        </div>
    )
}
export default Home;