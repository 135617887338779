import React, { Component } from "react";
import { ImLocation } from "react-icons/im";
import mapContainer from "../styles/components/mapContainer.css";
import { GoogleApiWrapper, Map, InfoWindow, Marker } from 'google-maps-react';

export class MapContainer extends Component {

    static defaultProps = {
        center: {
            lat: 25.5348354,
            lng: -103.3698971
        },
        zoom: 15,
    };
    render() {
        const containerStyle = {
            position: 'relative',  
            width: '100%',
            height: '100%'
          }
        return (
            <div className="map-container">
                {/*<GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyApHBzojbqc9llt8AClLcX28SzQFNmeUrU' }}
                    defaultCenter={{ 
                        lat: this.props.center.lat,
                        lng: this.props.center.lng 
                    }}
                    defaultZoom={this.props.zoom}
                >
                    <LocationPin
                        lat={this.props.center.lat}
                        lng={this.props.center.lng}
                        text={this.props.address}
                    />
                </GoogleMapReact>*/}
                <Map
                    containerStyle={containerStyle}
                    google={this.props.google}
                    zoom={this.props.zoom}
                    initialCenter={{
                        lat: this.props.center.lat,
                        lng: this.props.center.lng
                    }}>
                    <Marker
                        name={'Hedesa'}
                        position={{
                            lat: this.props.center.lat,
                            lng: this.props.center.lng
                        }} />
                    <Marker />
                    <InfoWindow>
                        <div>
                            <p>Click on the map or drag the marker to select location where the incident occurred</p>
                        </div>
                    </InfoWindow>
                </Map>
            </div>
        )
    }
}

export default GoogleApiWrapper({
    //AIzaSyCXfHxVcHPXR5r-2_1QqJb6gg8WPXR9jho
    // apiKey: ('AIzaSyApHBzojbqc9llt8AClLcX28SzQFNmeUrU')
    //apiKey: ('AIzaSyBEaDa_x9tKLzs7jDa_uAltVQhKu9s5r9Y') 
    apiKey: ('AIzaSyCXfHxVcHPXR5r-2_1QqJb6gg8WPXR9jho') 
})(MapContainer)
