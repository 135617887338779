import NavbarComp from "./components/Nav/NavbarComp";
import Navbar from "./components/NavBurger/Navbar";
import Footer from './components/Footer';
import { BrowserRouter as Router, Route, Switch, HashRouter } from 'react-router-dom'
import { Home, AboutUs, Catalog, Contact } from './pages'
import 'bootstrap/dist/css/bootstrap.min.css';
import "./styles/main.css";
import { Altura, Compactacion, Concreto, Demolicion, Generadores, Otros, Pintura, Soldadura, Excavaciones } from './components/Catalogs/index'
import { FaPhoneAlt } from "react-icons/fa";
import { MdMail } from "react-icons/md";


//test push 1
function App() {
  return (
 
    <>
      <div className="info row m-0">
        <div className='col-6 col-lg-3'><FaPhoneAlt className="icon info-header-text" /><span className="icon info-header-text" >8714574448</span></div>
        <div className='col-6 col-lg-3'><FaPhoneAlt className="icon info-header-text" /><span className="icon info-header-text" >8712927464</span></div>
        <div className='col-12 col-lg-6'>
          <MdMail className="icon info-header-text" />
            <span className="icon info-header-text" >
              claudia.torres@hedesarentasmaquinaria.com
            </span>
        </div>       
      </div>
      <HashRouter>
      {/* <Router> */}

        <NavbarComp/>
       
        <Switch>
          <Route exact path={'/'} component={Home} />
          <Route exact path={'/about-us'} component={AboutUs} />
          <Route exact path={'/catalog'} component={Catalog} />
          <Route path="/catalog/altura" component={Altura} />
          <Route path="/catalog/compactacion" component={Compactacion} />
          <Route path="/catalog/concreto" component={Concreto} />
          <Route path="/catalog/demolicion" component={Demolicion} />
          <Route path="/catalog/generadores" component={Generadores} />
          <Route path="/catalog/Pintura" component={Pintura} />
          <Route path="/catalog/soldadura" component={Soldadura} />
          <Route path="/catalog/Excavaciones" component={Excavaciones} />
          <Route path="/catalog/otros" component={Otros} />
          <Route exact path={'/contact'} component={Contact} />
        </Switch>
      
      {/* </Router> */}
      </HashRouter>
      <Footer />
    </>
  );
}

export default App;
