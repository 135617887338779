import React, { Fragment, useState } from 'react'
import { Modal } from 'react-bootstrap'
import contact from '../styles/pages/contact.css'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from "yup";
import emailjs from 'emailjs-com';


function Contact() {
    const [showModal, setShowModal] = useState(false)

    const handleSubmit = (values, actions) => {
        emailjs.init("user_qRkVBMFact3UxWi8xCutF")
        emailjs.send("service_hedesa", "template_x9n3j1s", {
            first_name: values.firstName,
            last_name: values.lastName,
            phone: values.phone,
            email: values.email,
            comments: values.comments,
        });
        actions.resetForm();
        setShowModal(true);
    }
    const SignupSchema = Yup.object().shape({
        firstName: Yup.string()
            .required('El campo nombre es requerido'),
        email: Yup.string()
            .required("Ingresa un correo electronico")
            .email('Ingresa un correo electronico valido'),
        comments: Yup.string().required('Ingresa un comentario')

    });


    const TextAreaComponent = (props) => (
        <textarea {...props} type="text" className="form-control" />

    );
    return (
        <Fragment>
            <div className="container main-container">
                <div className="row m-20">
                    <div className="col-12 col-lg-5 text-center img-container-contact">
                        {/* <img src="images/mail.png"></img> */}
                        <img className='img-row' src="images/mail.png"></img>
                        <h1 className="subtitle-contact">¡Contactanos!</h1>
                        {/* <p className='text-p px-4'>
                            Si tienes preguntas y quieres contactarnos, puedes usar el siguiente formulario para comunicarte con nosotros y te responderemos en la brevedad posible.
                        </p> */}
                    </div>

                    <div className="col-lg-7 col-12 form-main">
                        <div className="container">
                            <div className="row">
                                <Formik
                                    initialValues={{
                                        firstName: "",
                                        lastName: "",
                                        email: "",
                                        phone: "",
                                        comments: ""
                                    }}
                                    onSubmit={(values, actions) => handleSubmit(values, actions)}
                                    validationSchema={SignupSchema}
                                >
                                    {({ errors, touched }) => (
                                        <Form>
                                            <div className="col-12">
                                                <label className='text-p' htmlFor="inputAddress">Nombre</label>
                                                <Field id="firstName" name="firstName" type="text" className="form-control" />
                                                {/* {errors.name && touched.name ? (<div style={{color:'white'}}> {errors.name}</div>) : null} */}
                                                <ErrorMessage name="firstName">{msg => <label className='text-error' htmlFor="inputAddress">{msg}</label>}</ErrorMessage>
                                            </div>
                                            <div className="col-12">
                                                <label className='text-p' htmlFor="inputPassword4">Apellido</label>
                                                <Field id="lastName" name="lastName" type="text" className="form-control" />
                                            </div>
                                            <div className="col-12">
                                                <label className='text-p' htmlFor="phone">Télefono(opcional)</label>
                                                <Field maxLength={15} id="phone" name="phone" type="text" className="form-control" />
                                            </div>
                                            <div className="col-12">
                                                <label className='text-p' htmlFor="inputEmail4">Correo Electronico</label>
                                                <Field id="email" name="email" type="email" className="form-control" />
                                                <ErrorMessage name="email">{msg => <label className='text-error' >{msg}</label>}</ErrorMessage>
                                            </div>

                                            <div className="col-12">
                                                <label className='text-p'>Comentarios</label>
                                                <Field name="comments" as={TextAreaComponent} />
                                                <ErrorMessage name="comments">{msg => <label className='text-error'>{msg}</label>}</ErrorMessage>


                                            </div>
                                            <div className="col-12 text-end mt-4">
                                                <button type="submit" className="btn btn-confirm">Enviar</button>
                                            </div>
                                        </Form>
                                    )}

                                </Formik>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                size="sm"
                centered
                show={showModal}
                onHide={() => setShowModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="title-sm">
                        Mensaje enviado
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center' >
                        <p>Tu mensaje ha sido enviado éxitosamente, pronto nos contactaremos contigo</p>
                    </div>
                </Modal.Body>
            </Modal>

        </Fragment>

    );
}

export default Contact