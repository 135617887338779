import React from 'react'
import styled from 'styled-components'

const Ul = styled.ul`
list-style: none;
display: flex;
flex-flow: row nowrap;

li{
padding: 18px 10px;
}

@media (max-width: 768px){
    margin-top: 0;
flex-flow: column wrap;
background-color: #0D2538;
position: fixed;
transform:${({open}) => open ? 'translate(0)': "translate(100%)"};
top:0;
right: 0;
height: 100vh;
width: 300px;
padding-top: 3.5rem;
transition: transform 0.3s ease-in-out;

li{
    color: #f1f1f1;
}

}
`;

export const RightNav = ({open}) => {


    return (
        <Ul open={open}>
            <li>Home</li>
            <li>About us</li>
            <li>Contact Us</li>
            <li>Sign in</li>
            <li>Sign Up</li>
        </Ul>

    )
}
export default RightNav