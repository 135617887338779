import React, { Component } from 'react'
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap'

import logo from '../../img/home/noBkg-logo.png'
import navBar from '../../styles/components/navBar.css';
import { Link } from 'react-router-dom'
import categorias from '../../core/data/categorias.json';

export default class NavbarComp extends Component {
    render() {
        return (
            <div>
                <Navbar className='header-container' expand="lg">
                    <Container fluid>
                        <Navbar.Brand>
                          <Link to='/'>
                          {/* <img
                                src={"/noBkg-logo.png"}
                                className="d-inline-block align-middle logo-img d-none d-md-block"
                                alt="Logo"
                            />
                                <img
                                src={"/images/logo_icono_stroke.png"}
                                className="d-inline-block align-middle logo-img d-md-none"
                                alt="Logo"
                            /> */}
                            <img
                                src={"/noBkg-logo.png"}
                                className="d-inline-block align-middle logo-img"
                                alt="Logo"
                            />
                          

                            </Link>  
                        
                            
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbarScroll" />
                        <Navbar.Collapse id="navbarScroll">
                            <Nav className="menu-container" navbarScroll>
                                <Link className='link header' to="/">INICIO</Link>
                                {/* <Link className='link header' to="about-us" >¿QUIÉNES SOMOS?</Link> */}
                                <NavDropdown className='link header' title="CATEGORIAS" id="navbarScrollingDropdown">
                                    {categorias && categorias.data.map((item, i)=>(
                                        <NavDropdown.Item key={item.title + i}>   <Link className='link header' to={item.url}>{item.title}</Link></NavDropdown.Item>
                                    ))}
                                    {/* <NavDropdown.Item>   <Link className='link header bkg-ddl' to="/catalog/compactacion">COMPACTACIÓN</Link></NavDropdown.Item>
                                    <NavDropdown.Item><Link className='link header bkg-ddl' to="/catalog/subcatalog2">Catalogo2</Link></NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item href="/catalog/others">Other</NavDropdown.Item> */}
                                </NavDropdown>
                                <Link className='link header' to="/contact">CONTACTO</Link>

                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        )
    }
}

