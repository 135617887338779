import React from "react";
import { Route, Switch } from 'react-router-dom'
import { Card, CardGroup, Image } from 'react-bootstrap'


import bannercDrill from '../img/drills/cordedDrills/banner_Drill.jpg'
import BD from '../img/hedesa/Martillo_demoledor_makita.jpg'
import batavia from '../img/hedesa/revolvedora.png'
import hikoki from '../img/hedesa/allanadora_whiteman.jpg'
import makita from '../img/hedesa/hidrolavadora_ryobi.jpg'
import ryobi from '../img/hedesa/Airlees_Spayer_ClassisS395.jpg'
import uknow from '../img/hedesa/aplanadora_cipsa.jpg'
import data from '../core/data/compactacion.json';
import {Demolicion} from '../components/Catalogs/index'

function Catalog() {
  return (
    <Switch>
      {/* <Route exact path="/catalog" component={CatalogHome} /> */}
      {/* <Route path="/catalog/altura" component={Compactacion} />
      <Route path="/catalog/compactacion" component={Compactacion} />
      <Route path="/catalog/subcatalog2" component={SubCatalog2} />
      <Route path="/catalog/others" component={Others} />
      <Route path="/catalog/demolicion" component={Demolicion} /> */}
    </Switch>
  );
}





export default Catalog;